import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import {AnalyticsService, Metrics} from '../services/analytics.service';
import {SharedVariablesService} from '../services/sharedVariables.service';
import {OauthConfigurationService} from '../services/oauth.configuration.service';

@Component({
  selector: 'app-named-login-button',
  templateUrl: './named-login-button.component.html',
  styleUrls: ['./named-login-button.component.scss']
})
export class NamedLoginButtonComponent implements OnInit {
  expanded = false;

  constructor(private oauthService: OAuthService, private analyticsService: AnalyticsService,
              private sharedservice: SharedVariablesService, private oauthConfigService: OauthConfigurationService) {
  }

  ngOnInit() {
    this.updateUserStatusToConsole();
  }

  startAuthentication(): void {
    if (environment.DEBUG) { console.log(`Authentication initiated`); }
    this.analyticsService.logEvent(Metrics.LOGINSTART);
    this.oauthService.initCodeFlow();
  }

  removeAuthentication(): void {
    if (environment.DEBUG) { console.log('Logout initiated'); }
    this.sharedservice.resetData();
    window.sessionStorage.removeItem('me');
    this.oauthService.logOut();
    this.oauthConfigService.resetCookieStore();
  }

  updateUserStatusToConsole() {
    console.log(`Ingelogd: ${this.userAuthenticated() ? `ja, als ${(this.oauthService.getIdentityClaims() as any).sub}` : 'nee'}`);
  }

  userAuthenticated(): boolean {
    return this.oauthService && this.oauthService.hasValidIdToken();
  }
  public get initials() {
    const givenname = this.retrieveClaim('given_name');
    return givenname ? givenname.substr(0, 1).toUpperCase() : 'SC';
  }

  retrieveClaim(claimName: string) {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims ? claims[claimName] : null;
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  close(): void {
    if (this.expanded) {
      this.expanded = false;
    }
  }
}
