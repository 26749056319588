import {Component, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {OauthConfigurationService} from '../services/oauth.configuration.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AnalyticsService, Metrics} from '../services/analytics.service';
import {timer} from 'rxjs';


@Component({
  selector: 'app-oauth-route',
  templateUrl: './oauth.route.component.html',
  styleUrls: ['./oauth.route.component.scss']
})
export class OAuthRouteComponent implements OnInit {
  status = 'We loggen u zo snel mogelijk in.';
  details = '';
  message = '';
  retryLoginButton = false;

  constructor(private oauthConfigService: OauthConfigurationService,
              private oauthService: OAuthService,
              private route: ActivatedRoute,
              private router: Router,
              private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params && params.action === 'start') {
        this.oauthService.initCodeFlow();
        return;
      }
    });
    this.oauthConfigService.configLoaded.subscribe(
      (configLoaded: boolean) => {
        if (configLoaded) {
          this.oauthService.tryLogin().then((loginResult) => {
            const savedUri = sessionStorage.getItem('sc-preauth-uri');
            if (!loginResult) {
              this.status = 'Het inloggen is niet gelukt.';
            } else {
              sessionStorage.removeItem('sc-preauth-uri');
              this.analyticsService.logEvent(Metrics.LOGINDONE);
            }
            this.updateUserStatusToConsole();
            if (savedUri !== undefined && savedUri != null) {
              this.router.navigateByUrl(savedUri);
            } else {
              this.router.navigateByUrl('/');
            }
          }).catch((loginError) => {
            const reden = JSON.stringify(loginError);
            this.status = 'Het inloggen is niet gelukt.';
            this.details = reden;
            if (loginError?.type === 'invalid_nonce_in_state') {
              // first we try cookiestorage, if this also fails we are not dealing with (currently only firefox) session issues.
              if (!this.oauthConfigService.isCookieStorageInit()){
                this.status = 'We loggen u zo snel mogelijk in';
                this.details = 'Over 5 seconden wordt u doorgestuurd. Of druk op onderstaande knop.';
                this.retryLoginButton = true;
                timer(5000).subscribe(_ => { this.retryLogin(); });
                return;
              }
              this.message = 'De fout die is opgetreden kan komen doordat u in incognito-modus Somtoday Connect probeert te benaderen. Indien dit het geval is, vragen we u het in een gewoon venster opnieuw te proberen voordat u contact met ons opneemt. Alvast bedankt. (invalid_nonce_in_state)';
            }
            console.error(JSON.stringify(loginError));
          });
        }
      });

  }

  public retryLogin(): void {
    return this.oauthConfigService.useCookieStorageAndRetryLogin();
  }

  private updateUserStatusToConsole(): void  {
    if (environment.DEBUG) {
      console.log(`
      %c Ingelogd: ${this.oauthService.hasValidIdToken() ? `ja, als ${(this.oauthService.getIdentityClaims() as any).sub}` : 'nee'}
    `, 'color: red');
      const claims: any = this.oauthService.getIdentityClaims();
      console.log(`Ingelogd als ${claims && claims.sub}`);
    }
  }
}
