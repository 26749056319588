import { ConnectConfiguratie } from './environment.service';
import {EnabledConfig} from './required.connect.config';

class TestConfiguratie extends ConnectConfiguratie {
  configurationName(): string {
    return 'Test';
  }

  production(): boolean {
    return false;
  }

  DEBUG(): boolean {
    return false;
  }

  getPrefix(): string {
    return 'test';
  }
  isSecure(): boolean {
    return true;
  }
  apiUrl(): string {
    return `https://api.connect.test.somtoday.nl/`;
  }

  dashboardApiUrl(): string {
    return `https://etl.connect.test.somtoday.nl`;
  }

  public idpUrl(): string {
    return `https://inloggen.test.somtoday.nl`;
  }

  idpIssuer(): string {
    return `https://test.somtoday.nl`;
  }

  analyticsId(): string {
    return 'UA-163203739-3';
  }

  validationJwks(): object
    {
      return {
        keys: [{
          kty: 'RSA',
          'x5t#S256': 'tuXEeVyM1zc7lWjMGnaHkNoefE4Oexvu9-QFqGhdEYQ',
          e: 'AQAB',
          use: 'sig',
          kid: 'iridiumidp-112062481074406544351379326342027084348',
          alg: 'RS256',
          n: 'vrvoUflZg7i1bwFWO4l42wuvyGpT-HEf21xF1QtRlZc_C96DFfaM1N1OdGIvWZGNAUMVKCj50VWge0YjOzU8c0g01UcVBp5_zMWgeTV2-lRpJLzWkQc5zFnzRgykLyGhkEYk-UNRbNXI4zica_2LHU-w6CdD7pl2-mAHN99Me9DI64i1bi5vyAEEx3NRtPOX-cdbgxoV1N6tmFCgvm4zvoML27599_LJyzHnZWSTtD903Cki98mY7hHbgSt1bG08c2NIF5ltGt_6JpIKdssV19AydK_mVywRXOmovsxgswTf04PaCUHFDrYS9NLyfMtYpBa2mppxrPD7765tGymd0w'
        }]
      };
    }

  bureauICEKoppelpartijUUID(): string {
    return '7a58f029-4627-4fcd-b964-c5d3b6454e49';
  }

  newDashboardsEnabled(): EnabledConfig {
    return {
      enabled: false
    };
  }

  newStudiewijzerLinkjesEnabled(): EnabledConfig {
    return {
      enabled: false
    };
  }

  oldDashboardsEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  oldStudiewijzerLinkjesEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }
}

const testConfig: TestConfiguratie = new TestConfiguratie();

export const environment = testConfig.build();
